(function ($, Drupal) {
  Drupal.behaviors.blockSearch = {
    attach: function (context, settings) {
      /////////////////
      var $smInputSearch = $('#sm-custom-search-box');

      $smInputSearch.once().each(function () {
        var $this = $(this);

        $this.focusout(function () {
          var $this = $(this);

          if ($this.val() !== "") {
            $this.addClass('has-content');
          } else {
            $this.removeClass('has-content');
          }
        });
      });

      /////////////////
    }
  };

})(jQuery, Drupal);